import React from "react"
import { graphql } from "gatsby"
import Seo from "../../components/seo"
import MoreStories from "../../components/blog/MoreStories"
import styled from "styled-components"
import PostHeader from "../../components/blog/PostHeader"
import PostBody from "../../components/blog/PostBody"
import { CustomThemeProvider } from "../../theme/ThemeProvider"
import Layout from "../../components/Layout"

const Post = ({ data: { post, morePosts } }) => {
  const defaultImage = post?.coverImage?.gatsbyImageData?.images?.fallback?.src
  return (
    <CustomThemeProvider>
      <Layout>
        <Background>
          <MainWrapper>
            <Seo title={post.title} defaultImage={defaultImage} />
            <article>
              <PostHeader
                title={post.title}
                coverImage={post.coverImage}
                date={post.date}
                author={post.author}
              />
              <PostBody content={post.content} />
            </article>
            <Separator />
            {morePosts.nodes.length > 0 && (
              <MoreStories posts={morePosts.nodes} />
            )}
          </MainWrapper>
        </Background>
      </Layout>
    </CustomThemeProvider>
  )
}

export default Post

const Background = styled.section`
  background-color: ${({ theme }) => theme.colors.background};
`

const MainWrapper = styled.div`
  padding: 5rem 0px 2rem 0px;
  max-width:1280px;
  margin: 0 auto;

  @media (max-width:1334px) {
    padding-left:10%;
    padding-right:10%;
  }
`

const Separator = styled.hr`
  height:1px;
  border:none;
  background-color: ${({ theme }) => theme.colors.gray};
  opacity:0.7;
  margin-top:2rem;
`

export const query = graphql`
  query PostBySlug($id: String) {
    post: datoCmsPost(id: { eq: $id }) {
      title
      slug
      content {
        value
        blocks {
          __typename
          id: originalId
          image {
            gatsbyImageData(width: 700)
          }
        }
      }
      date
      coverImage {
        gatsbyImageData
      }
      author {
        name
        picture {
          gatsbyImageData(
            layout: FIXED
            width: 48
            height: 48
            imgixParams: { sat: -100 }
          )
        }
      }
    }
    morePosts: allDatoCmsPost(
      sort: { fields: date, order: DESC }
      limit: 2
      filter: { id: { ne: $id } }
    ) {
      nodes {
        title
        slug
        excerpt
        date
        coverImage {
          small: gatsbyImageData(width: 600 height:400)
        }
        author {
          name
          picture {
            gatsbyImageData(
              layout: FIXED
              width: 48
              height: 48
              imgixParams: { sat: -100 }
            )
          }
        }
      }
    }
  }
`

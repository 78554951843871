import React from "react"
import styled from "styled-components"
import Avatar from "./Avatar"
import CoverImage from "./CoverImage"
import Date from "./Date"

const PostHeader = ({ title, coverImage, date, author }) => {
  return (
    <>
      <PostTitle>{title}</PostTitle>
      <AvatarWrapper>
        <Avatar name={author?.name} picture={author?.picture} dateString={date}/>
      </AvatarWrapper>
      <CoverImage title={title} fluid={coverImage?.gatsbyImageData} />
      <DateWrapper>
        <Date dateString={date} />
      </DateWrapper>
    </>
  )
}

export default PostHeader

const PostTitle = styled.h1`
  color: ${({ theme }) => theme.colors.fontBlack};
  font-size: clamp(2rem,5vw,3rem);
  font-family: InterBold;
  margin-bottom: 1rem;
  margin-top:3rem;
`

const AvatarWrapper = styled.div`
  margin-bottom: 2rem;
`
const DateWrapper = styled.div`
  margin-top:2rem;
  margin-bottom:1rem;
`
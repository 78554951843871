import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { StructuredText } from "react-datocms"
import styled from "styled-components"

const PostBody = ({ content }) => {
  return (
    <MainWrapper>
      <MainContent>
        <StructuredText
          data={content}
          renderBlock={({ record }) => {
            if (record.__typename === "DatoCmsImageBlock") {
              return (
                <ImageContainer>
                  <GatsbyImage image={record.image.gatsbyImageData} />
                </ImageContainer>
              )
            }
            return (
              <>
                <p>Don't know how to render a block!</p>
                <pre>{JSON.stringify(record, null, 2)}</pre>
              </>
            )
          }}
        />
      </MainContent>
    </MainWrapper>
  )
}

export default PostBody

const MainWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const MainContent = styled.div`
  color: ${({ theme }) => theme.colors.fontBlack};
  /* padding: 0px 4rem; */
  max-width: 1000px;
  font-size: 1.32rem;
  line-height: 1.6;
`
